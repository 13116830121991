/** @jsxImportSource @emotion/react */
import { Box } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import { LocalizedLink } from "../../../LocalizedLink";
import ButtonWithUnderline from "../../ButtonWithUnderline";

const WeAreHiring = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        marginBottom: "4px",
        padding: "8px 16px",
        borderRadius: "40px",
        ":active": {
          backgroundColor: "rgba(235, 104, 61, 0.12)",
        },
      }}
    >
      <LocalizedLink to="/careers">
        <StaticImage
          src="../images/PartyPopper.png"
          alt=""
          css={{
            width: "24px",
            height: "24px",
            marginBottom: "4px",
            marginRight: "8px",
          }}
        />
      </LocalizedLink>
      <ButtonWithUnderline
        link="/careers"
        sx={
          {
            // display: "flex",
            // flexDirection: "row",
            // height: "47px",
            // alignItems: "center",
          }
        }
        textStyle={{ fontWeight: 700 }}
      >
        <Box component="span" sx={{ overflow: "visible", color: "#000" }}>
          We are hiring!{" "}
        </Box>

        {/* <Box
        component={"span"}
        sx={{
          width: "32px",
          height: "32px",
          backgroundColor: "blue",
          objectFit: "contain",
        }}
        >
      </Box> */}
      </ButtonWithUnderline>
    </Box>
  );
};

export default WeAreHiring;
