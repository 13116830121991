/** @jsxImportSource @emotion/react */
import { Box, Button, Typography } from "@mui/material";
import { animated, useSpring } from "@react-spring/web";
import { useRef } from "react";
import { LocalizedLink } from "../../../LocalizedLink";
import GetInTouchIcon from "./GetInTouchIcon";

const AnimatedBox = animated(Box);

const GetInTouchButton = () => {
  const [spring, api] = useSpring(() => {
    return {
      value: -1,
      config: {
        duration: 300,
      },
    };
  });

  const hasFinishedAnimating = useRef(false);

  return (
    <Button
      disableTouchRipple
      onMouseEnter={() => {
        hasFinishedAnimating.current = false;
        api.set({
          value: -1,
        });
        api.start({
          value: 0,
          onResolve: () => {
            hasFinishedAnimating.current = true;
          },
        });
      }}
      onMouseLeave={() => {
        if (!hasFinishedAnimating.current) {
          api.start({
            value: -1,
          });
        } else {
          api.start({
            value: 1,
          });
        }
      }}
      variant="text"
      endIcon={<GetInTouchIcon />}
      sx={{
        backgroundColor: "transparent",
        borderRadius: "40px",
        ":hover": {
          backgroundColor: "transparent",
          ":active": {
            backgroundColor: "rgba(235, 104, 61, 0.12)",
          },
        },
      }}
    >
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: "16px",
          textTransform: "none",
          color: "#000",
          position: "relative",
          overflow: "hidden",
        }}
        component="span"
      >
        <LocalizedLink
          to="/contacts"
          css={{ textDecoration: "none", color: "inherit" }}
        >
          Get in touch
        </LocalizedLink>
        <AnimatedBox
          style={{
            transform: spring.value
              .to([-1, 0, 1], [-100, 0, 100])
              .to((value) => `translateX(${value}%)`),
          }}
          sx={{
            width: "100%",
            height: "2px",
            backgroundColor: "black",
          }}
        />
      </Typography>
    </Button>
  );
};

export default GetInTouchButton;
