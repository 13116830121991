/** @jsxImportSource @emotion/react */
import { Box, IconButton, Modal } from "@mui/material";
import { animated, easings, useSpring } from "@react-spring/web";
import { useEffect, useRef, useState } from "react";
import MenuIcon from "../Topbar/components/MenuButtonIcon";
import MenuContent from "./components/MenuContent";
import { ModalContext, useModalContextValue } from "./contexts/ModalContext";
const AnimatedBox = animated(Box);

export const MenuSize = {
  thumbSize: { md: 47, xs: 32 },
  padding: { md: 32, xs: 24 },
};

const Menu = ({
  isOpen,
  setMenuOpen,
}: {
  isOpen: boolean;
  setMenuOpen: (isOpen: boolean) => void;
}) => {
  const [diagonal, setDiagonal] = useState(1);
  const radius = diagonal / 2;

  const modalContextValue = useModalContextValue({ isOpen, setMenuOpen });
  const scrollbarWidthRef = useRef(1);
  const backgroundColor = "#EB683D";

  useEffect(() => {
    let debounce: NodeJS.Timeout;

    const calcDiagonal = () => {
      if (debounce) clearTimeout(debounce);
      debounce = setTimeout(() => {
        setDiagonal((d) => {
          // const browserZoomLevel = (window.outerWidth - 8) / window.innerWidth;

          const value =
            typeof window !== "undefined"
              ? Math.sqrt(window.innerWidth ** 2 + window.innerHeight ** 2) + 60
              : 1;

          // const value = typeof window !== "undefined" ? Math.sqrt(window.outerWidth ** 2 + window.outerHeight ** 2) + 50 : 1;

          return value;
          // return value / browserZoomLevel;
        });
      }, 100);
    };
    calcDiagonal();
    addEventListener("resize", calcDiagonal);

    scrollbarWidthRef.current =
      typeof window !== "undefined"
        ? window.innerWidth - document.body.offsetWidth
        : 0;

    return () => {
      removeEventListener("resize", calcDiagonal);
    };
  }, []);

  const [spring] = useSpring(() => {
    return {
      value: isOpen ? 0 : 1,
      onResolve: () => {
        if (!isOpen) {
          modalContextValue.setIsActuallyOpen(false);
        }
      },
      delay: !isOpen ? 300 : 0,
      config: {
        duration: 600,
        easing: isOpen ? easings.easeInQuart : easings.easeOutQuart,
      },
    };
  }, [isOpen]);

  return (
    <ModalContext.Provider value={modalContextValue}>
      <Box
        sx={{
          width: {
            md: `${MenuSize.thumbSize.md}px`,
            xs: `${MenuSize.thumbSize.xs}px`,
          },
          height: {
            md: `${MenuSize.thumbSize.md}px`,
            xs: `${MenuSize.thumbSize.xs}px`,
          },
          position: "relative",
        }}
        // placeholder per avere il bottone posizionato nel posto corretto
      />

      <IconButton
        children={
          <Box
            sx={{
              position: "absolute",
              // left: -50,
              // right: -50,
              margin: "auto",
            }}
          >
            <Box
              sx={{
                fontSize: 0,
                // opacity: 0,
                transform: {
                  md: "scale(1)",
                  xs: `scale(${MenuSize.thumbSize.xs / MenuSize.thumbSize.md})`,
                },
                // backgroundColor: "lime",
              }}
            >
              <MenuIcon isMenuOpen={isOpen} />
            </Box>
          </Box>
        }
        disableRipple
        sx={{
          webkitJustifycontent: "left",
          justifyContent: "left",

          position: "fixed",
          top: "32px",
          zIndex: modalContextValue.isActuallyOpen ? 1500 : 1000,
          padding: 0,
          cursor: "pointer",
          left: {
            md: `calc(100vw - ${MenuSize.padding.md}px - ${MenuSize.thumbSize.md}px - ${scrollbarWidthRef.current}px)`,
            xs: `calc(100vw - ${MenuSize.padding.xs}px - ${MenuSize.thumbSize.xs}px - ${scrollbarWidthRef.current}px)`,
          },
          width: {
            md: `${MenuSize.thumbSize.md}px`,
            xs: `${MenuSize.thumbSize.xs}px`,
          },
          height: {
            md: `${MenuSize.thumbSize.md}px`,
            xs: `${MenuSize.thumbSize.xs}px`,
          },
          ".menuContainerRect": {
            transition: "fill .2s",
            transitionTimingFunction: "ease-out",
          },
          ":hover": {
            ".menuContainerRect": {
              fill: isOpen ? "rgba(255, 255, 255, .88)" : "#EB683D",
            },
          },
        }}
        onClick={() => {
          setMenuOpen(!isOpen);
        }}
      />

      <AnimatedBox
        sx={{
          borderRadius: "50%",
          backgroundColor,
          width: diagonal + "px",
          height: diagonal + "px",
          transformOrigin: {
            md: `calc(${radius}px + 50vw - ${scrollbarWidthRef.current}px - ${MenuSize.padding.md}px - 8px) calc(${radius}px - 50vh + 32px + 8px)`,
            xs: `calc(${radius}px + 50vw - ${scrollbarWidthRef.current}px - ${MenuSize.padding.xs}px - 8px) calc(${radius}px - 50vh + 32px + 8px)`,
          },
          position: "fixed",
          top: `calc(50vh - ${radius}px)`,
          left: `calc(50vw - ${radius}px)`,
          pointerEvents: "none",
          zIndex: 1100,
        }}
        style={{
          transform: spring.value
            .to([0, 1], [1, 1 / diagonal])
            .to((value) => `scale(${value})`),
        }}
      />

      <Modal open={modalContextValue.isActuallyOpen} disablePortal hideBackdrop>
        <>
          <animated.div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor,
              pointerEvents: "none",
              opacity: spring.value.to((value) => (value < 0.01 ? 1 : 0)),
            }}
          />
          <MenuContent scrollbarWidth={scrollbarWidthRef.current} />
        </>
      </Modal>
    </ModalContext.Provider>
  );
};

export default Menu;
