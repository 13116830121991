import { Typography } from "@mui/material";
import React from "react";
import { usePage } from "../../../../layouts";
import { LocalizedSwitch } from "../../../LocalizedLink";
const locales = require("../../../../locales");

const LanguageButton = ({ whitened }: { whitened?: boolean }) => {
  const page = usePage();
  const langs = Object.keys(locales);
  return (
    <Typography
      sx={{
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: 700,
        userSelect: "none",
        position: "relative",
      }}
    >
      <LocalizedSwitch style={{ textDecoration: "none" }}>
        {langs.map((lang, index) => (
          <React.Fragment key={lang}>
            <Typography
              component="span"
              sx={{
                fontSize: "inherit",
                lineHeight: "inherit",
                cursor: page.locale === lang ? undefined : "pointer",
                fontWeight: page.locale === lang ? 700 : 400,
                color:
                  whitened && page.locale === lang
                    ? "#fff"
                    : whitened && page.locale !== lang
                    ? "#ffffff70"
                    : page.locale === lang
                    ? "#000"
                    : "#00000070",
              }}
            >
              {index === 0 ? "" : " / "} {lang}
            </Typography>
          </React.Fragment>
        ))}
      </LocalizedSwitch>
    </Typography>
  );
};

export default LanguageButton;
