/** @jsxImportSource @emotion/react */
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { animated, easings, useSpring } from "@react-spring/web";
import { StaticImage } from "gatsby-plugin-image";
import { useEffect, useRef, useState } from "react";
import { LocalizedLink } from "../../LocalizedLink";
import Menu, { MenuSize } from "../Menu/Menu";
import GetInTouchButton from "./components/GetInTouchButton";
import LanguageButton from "./components/LanguageButton";
import WeAreHiring from "./components/WeAreHiring";

const Topbar = () => {
  const theme = useTheme();
  const isOverMd = useMediaQuery(theme.breakpoints.up("md"));
  const [isOnTop, setIsOnTop] = useState(true);

  useEffect(() => {
    let scrollEL = () => {
      if (window.scrollY > 20) {
        setIsOnTop(false);
      } else {
        setIsOnTop(true);
      }
    };

    addEventListener("scroll", scrollEL);

    return () => {
      removeEventListener("scroll", scrollEL);
    };
  }, []);

  const AnimatedBox = animated(Box);
  const propsBox = useSpring({
    opacity: isOnTop ? 1 : 0,
    config: { duration: 400, easing: easings.easeInOutQuint },
  });

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const lastOpenCloseMillis = useRef(0);

  function setMenuOpen(open: boolean) {
    const currentMillis = new Date().getTime();

    if (currentMillis - lastOpenCloseMillis.current > 400) {
      setIsMenuOpen(open);
      lastOpenCloseMillis.current = currentMillis;
    }
  }

  return (
    <Box
      sx={{
        background:
          "linear-gradient(0deg, rgba(232, 83, 34, 0.03), rgba(232, 83, 34, 0.03)), rgba(255, 255, 255, .8);",
      }}
    >
      <Box
        sx={{
          width: "100%",
          zIndex: 300,
        }}
      >
        <Box
          sx={{
            display: "flex",
            margin: "0 auto",
            justifyContent: "space-between",
            alignItems: "center",
            padding: {
              md: `${MenuSize.padding.md}px`,
              xs: `${MenuSize.padding.xs}px`,
            },
          }}
        >
          <LocalizedLink to={"/"}>
            <AnimatedBox
              style={{ ...propsBox }}
              sx={{ width: "138px", objectFit: "contain" }}
            >
              <StaticImage src="./images/Logo.png" alt="mabiloft" css={{}} />
            </AnimatedBox>
          </LocalizedLink>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              height: "47px",
              // border: "1px solid green",
              boxSizing: "border-box",
              gap: "40px",
            }}
          >
            {isOverMd && (
              <AnimatedBox style={{ ...propsBox }}>
                <WeAreHiring />
              </AnimatedBox>
            )}
            {isOverMd && (
              <AnimatedBox style={{ ...propsBox }}>
                <GetInTouchButton />
              </AnimatedBox>
            )}

            <AnimatedBox style={{ ...propsBox }}>
              <LanguageButton />
            </AnimatedBox>

            <Menu isOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Topbar;
